.p-inputtext {
  background-color: transparent;
  color: var(--default-contrast);
  border-color: var(--dividers-color);
  &:enabled:hover {
    border-color: var(--default-contrast);
  }
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px var(--primary-color), inset 0 0 0 1px var(--primary-color), inset 0 0 0 1px var(--primary-color), inset 0 0 0 1px var(--primary-color);
  border-color: var(--primary-color);
}
