@use 'sass:map';

@import '~@angular/material/theming';

// Font Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Theme
@import './scss/theme';

// Animations
@import './scss/animations/spin';

// Custom
@import './scss/dialog';
@import './scss/swal';
@import './scss/overlay';
@import './scss/scrollbar';
@import './scss/table';
@import './scss/form-field';
@import './scss/cards';

// Common
@import './scss/common';

@import './scss/variables';

@import './scss/ng-prime-custom';

// Snackbar Severity
/* design filled */
// @import './snackbar/mat-snackbar-severity-filled';

/* design light outlined */
@import './snackbar/mat-snackbar-severity-outlined.scss';

* {
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.si-shadow-wrapper-top,
.si-content-wrapper {
  margin-top: 72px !important;
  border-radius: 0.5rem !important;
}

.si-content-wrapper {
  padding: 16px !important;
}

.si-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.active > .page-link,
.page-link.active {
  background-color: #383838 !important;
  border-color: #383838 !important;
}
